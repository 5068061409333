import React from 'react';
import CardLists from './CardLists';

import requests from '../../Request';


function ProductListCard({dataList}) {
    return (
        <>
        {dataList.map((item, key) => (
            <div key={key} className="mt-2 mb-2">
                <img src={requests.imageUrl+item.imageUrl} alt={`TIMSUN ${dataList.name} ${dataList.model}`} className="w-full" />
                <div className='mt-2 font-bold text-xl text-center'>
                    <span className="text-green-700">TIMSUN</span>&nbsp;{item.model}&nbsp;<span className="text-green-700">{item.name}</span>
                </div>
                <CardLists cardList={item.lists} />
            </div>
        ))}
        </>
    )

}

export default ProductListCard;