import React, { useEffect, useState } from 'react';
import axios from "axios";
import ProductListCard from './ProductListCard';
import requests from '../../Request';


function ProductList() {

  const [category, setCategory] = useState([]);

  useEffect( () => {
    const init = () => {
      axios.get(requests.categoryPrice).then((response) => {
        setCategory(response.data);
      });
    };

    init();

  }, []);

    return (
      <div className="flex flex-col">
        <div className="grid grid-cols-1 xl:grid-cols-4 gap-2">
        {category.map((item, key) => (
            <div key={key} className="px-2 py-2 bg-[#262626] text-white">
                <ProductListCard dataList={item.parts} />
            </div>
        ))}
        </div>
      </div>
    );
  }
  
  export default ProductList;
  