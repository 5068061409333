
import Namdang from '../assets/images/Services/namdang.jpg';
import NamdangMap from '../assets/images/Services/namdang_location.jpg';

import Rangsit from '../assets/images/Services/rangsit.jpg';
import RangsitMap from '../assets/images/Services/rangsit_location.jpg';

import Bangcare from '../assets/images/Services/bangcare.jpg';
import BangcareMap from '../assets/images/Services/bangcare_location.jpg';

import ChiangMai from '../assets/images/Services/chiangmai.jpg';
import ChiangMaiMap from '../assets/images/Services/chiangmai_location.jpg';

import Pattaya from '../assets/images/Services/pattaya.jpg';
import PattayaMap from '../assets/images/Services/pattaya_location.jpg';

import Phuket from '../assets/images/Services/phuket.jpg';
import PhuketMap from '../assets/images/Services/phuket_location.jpg';

import Ladphaw from '../assets/images/Services/ladphaw.jpg';
import LadphawMap from '../assets/images/Services/ladphaw_location.jpg';


const serviceData = [
  {
    name: "ทิมสันหนามแดง",
    mapUrl: "https://goo.gl/maps/44xjUE6MzxbzbRjS8",
    image: Namdang,
    mapImage: NamdangMap,
  },
  {
    name: "ทิมสันฟิวเจอร์พาร์ครังสิต",
    mapUrl: "https://goo.gl/maps/W6VRAJggEz99n1Ho9",
    image: Rangsit,
    mapImage: RangsitMap,
  },
  {
    name: "ทิมสันเดอะมอลล์บางแค",
    mapUrl: "https://goo.gl/maps/s44wYPvn1PFYCMks9",
    image: Bangcare,
    mapImage: BangcareMap,
  },
  {
    name: "ทิมสันเชียงใหม่",
    mapUrl: "https://goo.gl/maps/KRfgrSBudBPCeNaf9",
    image: ChiangMai,
    mapImage: ChiangMaiMap,
  },
  {
    name: "ทิมสันพัทยา",
    mapUrl: "https://goo.gl/maps/Zjwhj2neHyZwpdv27",
    image: Pattaya,
    mapImage: PattayaMap,
  },
  {
    name: "ทิมสันภูเก็ต",
    mapUrl: "https://g.page/TimsunPhuket?share",
    image: Phuket,
    mapImage: PhuketMap,
  },
  {
    name: "ทิมสันลาดพร้าว",
    mapUrl: "https://goo.gl/maps/qrEe4x8edWynydTc6",
    image: Ladphaw,
    mapImage: LadphawMap,
  },
]

export default serviceData;