import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';
import LineContact from '../components/LineContact';

import Shop from '../assets/images/Services/shop.jpg';

import serviceData from './serviceData';

function ServicePage() {
  useDocumentTitle('ศูนย์บริการทิมสัน');
  return (
    <>
      <img src={Shop} alt="" className="w-full" />
        {serviceData.map((item, key) => (<div key={key} className='xl:flex mt-2 mb-2'>
            <img src={item.image} alt={item.name} className="w-full" />
            <a href={item.mapUrl} target="_blank" rel="noreferrer" className="w-full"><img src={item.mapImage} alt={item.name} className="w-full" /></a>
          </div>
        ))}
        <LineContact />
    </>
  );
}

export default ServicePage;
