import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';

import About1 from '../assets/images/About/about1.jpg';
import About2 from '../assets/images/About/about2.jpg';
import About3 from '../assets/images/About/about3.jpg';
import About4 from '../assets/images/About/about4.jpg';

function AboutPage() {
  useDocumentTitle('เกี่ยวกับทิมสัน');
    return (
      <>
        <img src={About1} alt="" className="w-full" />
        <img src={About2} alt="" className="w-full" />

        <div className="bg-[#58595b] text-white">
          <div className="px-6 py-4">
            <div className="font-bold text-xl mb-2">Timsun(Thailand).co.,ltd.</div>
            <p className="w-full text-white text-md mb-4 break-all">
              เรา บริษัท ทิมสัน (ไทยแลนด์) จำกัด เป็นผู้จัดจำน่ายและนำเข้ายางมอเตอร์ไซค์ ทิมสัน<br/>
              แต่เพียงผู้เดียวในประเทศไทย หลายปีมานี้เรามีความพยายามที่จะให้ความรู้ ความเข้าใจ<br/>
              เกี่ยวกับความสำคัญของยางมอเตอร์ไซค์ แก่ผู้ใช้รถใช้ถนน เพื่อเปลี่ยนค่านิยมและความเข้าใจ<br/>
              ผิดๆมาตลอดเกี่ยวกับยางแต่ละชนิด และหันมาให้ความสำคัญเรื่องยางมากขึ้นอย่างเป็นเหตุเป็นผล<br/>
              ซึ่งก็ได้รับความสนใจเป็นอย่างมากและกว้างขวาง เพราะเราเป็นอันดับต้นๆที่เปิดเผยกลไกร การทำงานของ<br/>
              ยางอย่างกระจ่าง กรรมวิธีการผลิต และชักชวนให้เลือกซื้อยางจากคุณภาพที่สามารถเห็นด้วยตาและทดสอบได้ มากกว่ายึดติด<br/>
              กับสินค้าแบบเก่าๆโดยมุ่งเน้นเรื่องความปลอดภัยและประสิธิภาพของยางเมื่อเกิดเหตุไม่คาดฝันเป็นสำคัญ<br/>
              มากกว่าการแสดงภาพลักษณ์ที่ฉาบฉวยในการกีฬา เพราะผู้ใช้ที่แท้จริง จะพบเจออุปสักบนท้องถนนจริงมากกว่าในเกมกีฬา<br/>
              และนั่นคือจุดประสงค์หลักของยางทิมสัน ยางที่ลดความเสี่ยงบนท้องถนน<br/><br/>

              หลังจากที่กลุ่มลูกค้าได้ขยายตัวขึ้นอย่างรวดเร็วในโลกออนไลน์ มีการสั่ง Delivery เป็นจำนวนมาก<br/>
              ทั้งใน กทม. และ ต่างจังหวัด และกลุ่มลูกค้าขยายวงเร็วมากจากประสปการณ์การบอกต่อถึงคุณภาพและความคุ้มค่า<br/>
              นอกจากเราจะนำเข้ายางที่มีคุณภาพที่ดีแล้วเรายังให้ความสำคัญกับการให้บริการของช่างที่ประจำศูนย์ไม่แพ้กัน<br/>
              1.ชำนาญ 2.ให้คำแนะนำได้ และ3.รอบคอบ ...จากความสำเร็จด้านการบริการนี้ จึงทำให้เรา ขยายสาขาออกไปอีก รวม 6 สาขา ทั้วประเทศ ในเวลาไม่ถึง 2 ปี<br/>
              เพื่อรองรับกลุ่มลูกค้าแต่ละจังหวัด ที่เจาะจงใช้ยางทิมสันเท่านั้น และในอนาคต จะขยายศูนย์บริการให้ทั่วถึงที่สุด<br/><br/>

              หากท่านกำลังมองหายางที่ใช้ได้นาน ยางที่นุ่มนวลแต่ไม่ย้วย<br/>
              ยางที่มีคุณภาพดีตลอดอายุ ยางที่ไม่สร้างปัญหาจุกจิกระหว่างทาง<br/>
              ยางที่ทำให้การขับขี่ทุกๆวันของท่าน ปลอดภัยขึ้น<br/>
              มั่นใจขึ้น อย่างที่รู้สึกได้ทันทีที่ใส่ อยากให้ลองไว้ใจทิมสัน<br/><br/>

              เรามีความภูมิใจที่ทำให้การขับขี่บนท้องถนน ปลอดภัยขึ้น จากยางที่ปลอดภัย และขอขอบคุณลูกค้าทิมสัน ทุกท่าน<br/>
              ที่ไว้วางใจให้ยางทิมสันดูแล ทุกครั้งที่เดินทาง


            </p>
            
          </div>
        </div>

        <img src={About3} alt="" className="w-full" />
        <img src={About4} alt="" className="w-full" />
      
      </>
    );
  }
  
  export default AboutPage;
  