import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import useDocumentTitle from "../components/useDocumentTitle";

import ProductBanner from "../assets/images/Product/ads_price.jpg";

import requests from "../Request";
import Spec from "../components/catalog/Spec";

function ProductDetailPage() {
  const { keyword } = useParams();
  const [product, setProduct] = useState([]);
  const [images, setImages] = useState([]);

  useEffect(() => {
    const init = () => {
      axios.get(requests.productInfo + keyword).then((response) => {
        setProduct(response.data);
      });

      axios.get(requests.productImage + keyword).then((response) => {
        setImages(response.data);
      });
    };

    init();
  }, [keyword]);

  useDocumentTitle(product.name);

  return (
    <div className="flex flex-col bg-[#262626]">
      <img src={ProductBanner} alt="" className="w-full" />
      <div className="m-3 p-3">
        <h1 className="text-white text-3xl font-bold text-center">
          ยางทิมสัน {product.name}
        </h1>
      </div>
      <Spec list={images} />

      <a
        href={'/product'}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 mt-5 rounded text-center"
      >
        แสดงรายการทั้งหมด
      </a>
    </div>
  );
}

export default ProductDetailPage;
