import React from 'react';
import Iframe from "../Iframe";
import youtubeData from "./youtubeData";

function Youtube({limit}) {
    return (
        <>
        <div className="flex flex-col bg-[#262626]">
          <div className="grid grid-cols-1">
            {!limit ? (
                youtubeData.map((item, key) => (
                    <Iframe key={key} title={item.title} code={item.code} />
                ))
            ) : (
                youtubeData.slice(0, limit).map((item, key) => (
                    <Iframe key={key} title={item.title} code={item.code} />
                ))
            )}
          </div>
        </div>
        </>
    );
  }
  
  export default Youtube;