import React, { useEffect, useState } from "react";
import axios from "axios";
import useDocumentTitle from "../components/useDocumentTitle";
import requests from "../Request";

function PromotionPage() {
  const [images, setImages] = useState([]);

  useDocumentTitle("โปรโมชันยางทิมสัน");

  useEffect( () => {
    axios.get(requests.getBanner+'9').then((response) => {
      setImages(response.data);
    });

  }, []);

  console.log(images)


  return (
    <div images="mx-auto pb-10 bg-[#262626]">
      {images.map((item) => (
      <div className="flex justify-center bg-[#262626]">
        <img
          src={requests.imageUrl + item.thumb}
          alt=""
          className="w-full xl:w-1/2 mt-2 mb-2"
        />
      </div>
      ))}
    </div>
  );
}

export default PromotionPage;
