function Iframe({title, code}) {
  return (
    <iframe 
        src={`https://www.youtube.com/embed/${code}`}
        className="w-full h-[350px] xl:h-[600px]  p-5" 
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        title={title}  allowFullScreen>
    </iframe>
  );
}

export default Iframe;