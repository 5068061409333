import AddFriend from "../assets/images/add_friend.jpg";
import QrCode from "../assets/images/timsun_qr.jpg";

function LineContact() {
  return (
    <>
      <div className="bg-[#58595b] grid place-items-center pb-10">
        <div>
          <a
            href="https://line.me/R/ti/p/%40328sdtnd"
            rel="noreferrer"
            target="_blank"
          >
            <img src={AddFriend} alt="" />
          </a>
        </div>
        <div>
          <a
            href="https://line.me/R/ti/p/%40328sdtnd"
            rel="noreferrer"
            target="_blank"
          >
            <img src={QrCode} alt="" />
          </a>
        </div>
      </div>
    </>
  );
}

export default LineContact;
