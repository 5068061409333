import { Outlet } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "./Footer";

const Layout = () => {
  return (

    <div className="flex justify-center items-center">
        <div className="min-h-screen min-w-screen">
            <Navbar />
            <Outlet />
            <Footer />
        </div>
    </div>
    
  );
};

export default Layout;
