import React from 'react';

function CardLists({cardList}) {
    return (
        <>
        {cardList.map((item, key) => (
            <div key={key} className="mt-2 pl-3 text-lg">
                {item.size} ขอบ {item.width} = {item.price} บาท / เส้น
            </div>
        ))}
        </>
    )

}

export default CardLists;