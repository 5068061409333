import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';
import LineContact from '../components/LineContact';

import Image1 from '../assets/images/Dealer/Timsun-service-partner-ads.jpg'
import Image2 from '../assets/images/Dealer/timsun-agent.jpg'

function DealerPage() {
    useDocumentTitle('ตัวแทนจัดจำหน่ายทิมสัน');

    return (
      <>
      <img src={Image1} alt="" className="w-full" />
      <img src={Image2} alt="" className="w-full" />
      <LineContact />
      </>
    );
  }
  
  export default DealerPage;
  