import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';
import LineContact from '../components/LineContact';

import HowToOrder1 from '../assets/images/del_1.jpg';
import HowToOrder2 from '../assets/images/del_2.jpg';

function HowToPage() {
  useDocumentTitle('วิธีสั่งซื้อสินค้าทิมสัน');
  return (
    <>
      <img src={HowToOrder1} alt="" className="w-full" />
      <img src={HowToOrder2} alt="" className="w-full" />
      <LineContact />
    </>
  );
}

export default HowToPage;
