const navData = [
    {
      name: "Home",
      href: "/",
    },
    {
      name: "ศูนย์บริการ",
      href: "/service",
    },
    {
      name: "วิธีสั่งซื้อ",
      href: "/how-to-order",
    },
    {
      name: "ราคาสินค้า",
      href: "/product",
    },
    {
      name: "สาระน่ารู้",
      href: "/blog",
    },
    {
      name: "เกี่ยวกับเรา",
      href: "/about",
    },
    {
      name: "ตัวแทนจัดจำหน่าย",
      href: "/dealer",
    },
  ];

export default navData;