import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineExternalLink } from "react-icons/hi";
import useDocumentTitle from "../components/useDocumentTitle";
import navData from "../components/navData";

import Tags from "../components/Tags";
import Brand from "../components/catalog/Brand";
import Youtube from "../components/youtube/Youtube";
import LineContact from "../components/LineContact";
import TopBanner from "../assets/images/TopBanner.jpg";
import SecondBanner from "../assets/images/SecondBanner.jpg";
import PromotionBanner from "../assets/images/PromotionBanner.gif";
import PriceBanner from "../assets/images/PriceBanner.jpg";
import OtherBrand from "../assets/images/Home/other-band.jpg";
import AdsMain from "../assets/images/Home/ads_main3.jpg";
import AdsMain4 from "../assets/images/Home/ads_main4.jpg";
import HowToOrder from "../assets/images/del_2.jpg";

function HomePage() {
  useDocumentTitle("Timsun Thailand Co.,Ltd.");
  return (
    <div>

      <div className="md:hidden flex flex-col p-2">
        <div className="grid grid-cols-2 gap-2">
          {navData.map((item, key) => (
            <Link 
              to={item.href}
              key={key} 
              className="flex w-full text-sm xl:text-lg font-bold leading-sm uppercase mt-2 px-2 py-2 bg-[#262626] text-white rounded cursor-pointer" >
                {item.name}&nbsp;<HiOutlineExternalLink className="mt-[2px] xl:mt-[5px]" />
              </Link>
          ))}
        </div>
      </div>

      <img src={TopBanner} alt="" className="w-full" />

      <Link to="/about">
        <img src={SecondBanner} alt="" className="w-full" />
      </Link>
      <Link to="/promotion">
        <img src={PromotionBanner} alt="" className="w-full" />
      </Link>
      <Link to="/product">
        <img src={PriceBanner} alt="" className="w-full" />
      </Link>

      <Tags />
      <Brand />
      <Link to="/product">
        <img src={OtherBrand} alt="" className="w-full" />
      </Link>
      <Link to="/product">
        <img src={AdsMain} alt="" className="w-full" />
      </Link>
      <Link to="/service">
        <img src={AdsMain4} alt="" className="w-full" />
      </Link>
      <Youtube limit={2} />
      <div className="m-5 text-center"><a href="https://www.youtube.com/channel/UCNDNlaSfCoIfN4aYJX147kw" rel="noreferrer" target="_blank" className="text-white font-bold text-xl justify-center flex">คลิก ดู VDO สาระเกี่ยวกับยาง ....เพิ่มเติม &nbsp;<HiOutlineExternalLink className="mt-[2px] xl:mt-[5px]" /> </a></div>
      <Link to="/how-to-order">
        <img src={HowToOrder} alt="" className="w-full" />
      </Link>
      <LineContact />
    </div>
  );
}

export default HomePage;
