const url = process.env.REACT_APP_API_URL;
const img_url = process.env.REACT_APP_IMG_URL;

const requests = {
  productList: `${url}product/category|getAllProduct`,
  productInfo: `${url}product/category|productInfo&keyword=`,
  productImage: `${url}product/category|productImage&keyword=`,
  getBanner: `${url}product/category|getBanner&banner_id=`,
  categoryPrice: `${url}product/category|getCategoryProduct`,
  imageUrl: img_url
};

export default requests;