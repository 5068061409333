import React from "react";
import requests from "../../Request";

function Spec({ list }) {
  return (
    <>
      <div className="flex flex-col">
        <div className="grid xl:grid-cols-2">
        {list.map((item) => (
           <img key={item.id} src={requests.imageUrl + item.thumb} alt={``} className="w-full p-2" />
        ))}
        </div>
      </div>
    </>
  );
}

export default Spec;
