import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';
import Tags from '../components/Tags';
import Brand from '../components/catalog/Brand';
import LineContact from '../components/LineContact';

import ProductBanner from '../assets/images/Product/ads_price.jpg';
import HowToOrder from '../assets/images/del_2.jpg';
import ProductList from '../components/catalog/ProductList';


function ProductPage() {
  useDocumentTitle('ราคาสินค้าทิมสัน');
    return (
      <>
        <img src={ProductBanner} alt="" className="w-full" />
        <Tags />
        <Brand />
        <ProductList />
        <img src={HowToOrder} alt="" className="w-full" />
        <LineContact />
      </>
    );
  }
  
  export default ProductPage;
  