import React from 'react';
import useDocumentTitle from '../components/useDocumentTitle';
import LineContact from '../components/LineContact';
import Youtube from '../components/youtube/Youtube';

import MediaBanner from '../assets/images/Blog/howto.jpg';

function MediaPage() {
  useDocumentTitle('สาระน่ารู้ของทิมสัน');
    return (
      <>
        <img src={MediaBanner} alt="" className="w-full" />
        <Youtube />
        <LineContact />
      </>
    );
  }
  
  export default MediaPage;
  