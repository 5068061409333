import React from "react";
import youtube from "../../assets/images/youtube.png";
import facebook from "../../assets/images/facebook.png";
import line from "../../assets/images/line.png";
import telephone from "../../assets/images/telephone.png";

const social = [
  {
    name: "youtube",
    href: "https://www.youtube.com/channel/UCNDNlaSfCoIfN4aYJX147kw",
    icon: youtube,
  },
  {
    name: "facebook",
    href: "https://www.facebook.com/timsunthailand",
    icon: facebook,
  },
  {
    name: "line",
    href: "https://line.me/R/ti/p/%40328sdtnd",
    icon: line,
  },
  {
    name: "telephone",
    href: "tel: 0859246565",
    icon: telephone,
  },
];

const Footer = () => {
  return (
    <div className="w-full flex justify-center items-center bottom-2 bg-[#474747]">
      <div className="flex flex-col justify-center items-center">
        <ul className="flex flex-row list-none justify-center items-center">
          {social.map((item, key) => {
            return (
              <li id={`icons-${item.name}`} key={key}>
                <a
                  href={item.href}
                  target="_blank"
                  className="flex justify-center items-center"
                  rel="noreferrer"
                >
                  <img
                    src={item.icon}
                    alt="icon"
                    className="w-14 cursor-pointer mx-4 my-4"
                  />
                </a>
              </li>
            );
          })}
        </ul>
        
        <div className="font-semibold text-sm mt-2 mb-4 text-white">
          © 2022 Timsun(Thailand) Co.,Ltd. All rights reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
