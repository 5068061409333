import { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from './components/layout/Layout';
import HomePage from './pages/HomePage';
import ServicePage from './pages/ServicePage';
import HowToPage from './pages/HowToPage';
import ProductPage from './pages/ProductPage';
import ProductDetailPage from './pages/ProductDetailPage';
import MediaPage from './pages/MediaPage';
import AboutPage from './pages/AboutPage';
import DealerPage from './pages/DealerPage';
import NotFoundPage from './pages/NotFoundPage';
import PromotionPage from './pages/PromotionPage';

function App() {
  return (
    <Fragment>
      <BrowserRouter>
        <Routes>
          <Route element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="service" element={<ServicePage />} />
            <Route path="how-to-order" element={<HowToPage />} />
            <Route path="product" element={<ProductPage />} />
            <Route path="product/:keyword" element={<ProductDetailPage />} />
            <Route path="promotion" element={<PromotionPage />} />
            <Route path="blog" element={<MediaPage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="dealer" element={<DealerPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
