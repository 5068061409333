import { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";

import TimsunHeader from '../assets/images/TimsunHeader.png';
import navData from "./navData";




function Navbar() {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <>
        <img src={TimsunHeader} alt="" className="w-full" />

        <nav className="w-full font-orbitron flex justify-center items-center bg-[#464646] lg:mx-auto lg:px-8 py-2">
          <div className="hidden md:flex justify-center items-center">
            <ul className="flex p-0">
              {navData.map((item, key) => {
                return (
                  <li id={`menu-${item.name}`} key={key}>
                    <Link
                      to={item.href}
                      className="flex text-center font-semibold text-xl text-white rounded-full cursor-pointer hover:bg-bgSecondary px-2 py-1 mx-3 lg:mx-8"
                    >
                      {item.name}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="flex relative px-2">
            {toggleMenu ? (<>
              <AiOutlineClose
                fontSize={28}
                className="md:hidden cursor-pointer text-white"
                onClick={() => setToggleMenu(false)}
              /> Menu
            </>) : (<div className="md:hidden cursor-pointer text-white flex"> 
              <AiOutlineMenu
                fontSize={28}
                onClick={() => setToggleMenu(true)}
              /> Menu
            </div>)}
            {toggleMenu && (
              <ul
                className="z-10 fixed top-0 -right-2 p-3 w-80 h-screen shadow-2xl md:hidden list-none
                      flex flex-col justify-start items-end rounded-md font-semibold bg-[#464646] z-50
                    "
              >
                <li className="text-xl w-full my-2">
                  <AiOutlineClose onClick={() => setToggleMenu(false)} className="text-white" />
                </li>
                {navData.map((item, key) => (
                  <li key={key} className="mx-6 cursor-pointer my-2 text-lg text-white">
                    <Link to={item.href}>{item.name}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>

        </nav>
        
    </>
  );
}

export default Navbar;
