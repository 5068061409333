import React from "react";
import { Link } from "react-router-dom";
import hondaCatalog from '../../assets/images/CarBanner/honda_catalog_banner.jpg';
import kawasakiCatalog from '../../assets/images/CarBanner/kawasaki_catalog_banner.jpg';
import triumphCatalog from '../../assets/images/CarBanner/triumph_catalog_banner.jpg';
import yamahaCatalog from '../../assets/images/CarBanner/yamaha_catalog_banner.jpg';

const imageBrand = [
    {
        name:"Honda",
        image: hondaCatalog,
        href: "/product/Honda",
    },
    {
        name:"Kawasaki",
        image: kawasakiCatalog,
        href: "/product/Kawasaki",
    },
    {
        name:"Triumph",
        image: triumphCatalog,
        href: "/product/Triumph",
    },
    {
        name:"Yamaha",
        image: yamahaCatalog,
        href: "/product/Yamaha",
    },

];

function Brand() {
  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-2 xl:grid-cols-4">
          {imageBrand.map((item, key) => (
            <Link to={item.href} key={key} >
              <img src={item.image} alt={item.name} className="w-full p-2" />
            </Link>
          ))}
        </div>
      </div>
    </>
  );
}

export default Brand;
