import React, { useEffect, useState } from "react";
import axios from "axios";
import { HiOutlineExternalLink } from "react-icons/hi";
import requests from "../Request";

function Tags() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    axios.get(requests.productList).then((response) => {
      setProducts(response.data);
    });
  }, []);

  return (
    <>
      <div className="flex flex-col">
        <div className="grid grid-cols-2 xl:grid-cols-6 gap-2">
          {products.map((item, key) => (
            <a
              href={`/product/${item.keyword}`}
              key={key}
              className="flex w-full text-sm xl:text-lg font-bold leading-sm uppercase mt-2 px-2 py-2 bg-[#262626] text-white rounded cursor-pointer"
            >
              {item.name}&nbsp;
              <HiOutlineExternalLink className="mt-[2px] xl:mt-[5px]" />
            </a>
          ))}
          <a
            href={`/promotion`}
            key="promotion"
            className="flex w-full text-sm xl:text-lg font-bold leading-sm uppercase mt-2 px-2 py-2 bg-red-700 text-white rounded cursor-pointer"
          >
            โปรโมชัน&nbsp;
            <HiOutlineExternalLink className="mt-[2px] xl:mt-[5px]" />
          </a>
        </div>
      </div>
    </>
  );
}

export default Tags;
